import React from "react";
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';

const CategoriesContent = (props) => {
  const posts = props.posts;
  const categories = [];
  const categoryNames = [];
  posts.map((post) => {
    let postCategories = post.frontmatter.categories;
    postCategories?.split(";").map((categoryName) => {
        if (categoryName !== null && !categoryNames.includes(categoryName)) {
            categoryNames.push(categoryName);
            categories.push({name: categoryName, count: 1});
        }
        else if (categoryName !== null && categoryNames.includes(categoryName)) {
            categories.filter((category) => category.name == categoryName)[0].count ++;
        }
    })
  })

  return (
    <div>
        <Box sx={{ display: 'grid', gap: 4, gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)'}, textAlign: "center" }}>
            {categories.map((category, index) => {
                return (
                    <Link key={index} to={`/categories/${category.name}`} style={{ textDecoration: "none" }}>
                        <Paper style={{ padding: "20px" }}>({category.count}) {category.name}</Paper>
                    </Link>
                )
            })}
        </Box>
    </div>
  );
};

const CategoriesPage = ({ data }) => (
  <Layout>
    <Seo title="Categories" />
    <Container maxWidth="xl">
      <Box sx={{ my: 4 }}>
        <Typography variant="h2" component="h2" gutterBottom>
            Categories
        </Typography>
        <CategoriesContent posts={data.allMarkdownRemark.nodes} />
      </Box>
    </Container>
  </Layout>
)

export default CategoriesPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
      filter: {frontmatter: {draft: {eq: true}}}) {
      nodes {
        frontmatter {
            categories
        }
      }
    }
  }
`
